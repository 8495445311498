import { useRouter } from 'next/router';
import { Button, ButtonProps } from '@chakra-ui/react';
import Link, { LinkProps } from './Link';
import { AcfButtonLink } from '../types';
import { useSelectedService } from '../lib/hooks.context';

const useAutoAcfLinkProps = () => {
  const { setSelectedService } = useSelectedService();
  const { asPath } = useRouter();

  return ({
    url,
    onClick,
    ...rest
  }: Omit<AcfButtonLink['link'], 'title'>): Partial<LinkProps> => {
    switch (url) {
      case '#service-request':
        return {
          href: url,
          onClick: (e) => {
            setSelectedService(asPath.split('#')[1]);
            if (onClick) {
              onClick(e);
            }
          },
          ...rest,
        };
      default:
        return { href: url, onClick, ...rest };
    }
  };
};

/* NOTE: This component should return <Button as={Link} /> which works fine, but causes warning about forwardRef passed by NextLink
 * Should be fixable, but it's low priority
 */
const ButtonLink = ({
  link: { title, locale, ...link },
  ...buttonProps
}: AcfButtonLink & Omit<ButtonProps, 'disabled'>) => {
  const withAutoLinkProps = useAutoAcfLinkProps();

  return (
    <Link
      title={title}
      locale={locale}
      textDecoration="none !important"
      display="inline-block"
      {...withAutoLinkProps(link)}
    >
      <Button as="span" {...buttonProps}>
        {title}
      </Button>
    </Link>
  );
};

export default ButtonLink;
